import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { BsFillPlayBtnFill } from "react-icons/bs";
import Swiper from "swiper";
import { SanityImage, SanityYoutubeVideo } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import { useIsRtl } from "../../utils/LayoutContext";
import PlainButton from "../Button/PlainButton/PlainButton";
import CarouselButton from "../CarouselButton/CarouselButton";
import SwiperCarousel from "../SwiperCarousel/SwiperCarousel";
import YoutubeVideo from "../YoutubeVideoBlock/YoutubeVideoBlock";
import styles from "./MediaCarousel.module.scss";

interface MediaCarouselProps {
  className?: string;
  media: (SanityImage | SanityYoutubeVideo)[];
}

const MediaCarousel = ({ media, className }: MediaCarouselProps) => {
  const [swiper, setSwiper] = useState<Swiper>();
  const isRtl = useIsRtl();
  const getKey = useCallback(
    (media: SanityImage | SanityYoutubeVideo) =>
      media._type === "simpleImage"
        ? (media as SanityImage).asset._id
        : (media as SanityYoutubeVideo).youtubeId,
    []
  );
  const slides = media.map(media => (
    <div key={getKey(media)} className={styles.slide}>
      {media._type === "simpleImage" ? (
        <img
          src={
            imageUrlBuilder
              .image(media)
              .width(800)
              .height(600)
              .url()!
          }
          alt={media.alt ?? ""}
          width={800}
          height={600}
        />
      ) : (
        <div className={styles.videoContainer}>
          <YoutubeVideo
            className={styles.video}
            node={media as SanityYoutubeVideo}
          />
        </div>
      )}
    </div>
  ));
  const thumbnails = media.map((media, index) => (
    <PlainButton key={getKey(media)} onClick={() => swiper?.slideTo(index)}>
      {media._type === "simpleImage" ? (
        <img
          src={
            imageUrlBuilder
              .image(media)
              .width(72)
              .height(54)
              .url()!
          }
          alt={media.alt ?? ""}
          width={72}
          height={54}
        />
      ) : (
        <div className={styles.videoThumbnail}>
          <BsFillPlayBtnFill color="rgb(50,50,50)" size="30" />
        </div>
      )}
    </PlainButton>
  ));

  useEffect(() => {
    const listener = (e: Swiper) => {
      e.slides.forEach(slide => {
        const iframe = slide.querySelector("iframe");
        if (iframe) {
          iframe.contentWindow?.postMessage(
            JSON.stringify({ event: "command", func: "pauseVideo" }),
            "*"
          );
        }
      });
    };
    if (swiper) {
      swiper.on("slideChange", listener);
    }
    return () => {
      if (swiper) {
        swiper?.off("slideChange", listener);
      }
    };
  }, [swiper]);

  return (
    <div className={styles.root}>
      <div className={classnames(styles.image, className)}>
        <CarouselButton
          direction={isRtl ? "right" : "left"}
          size="small"
          onClick={() => swiper?.slidePrev()}
          shadow={false}
        />
        <div className={styles.carousel}>
          <SwiperCarousel
            slidesPerView={1}
            itemTypeMessage="images"
            onSwiper={setSwiper}
            carouselItems={slides}
            loop
          />
        </div>
        <CarouselButton
          direction={isRtl ? "left" : "right"}
          size="small"
          onClick={() => swiper?.slideNext()}
          shadow={false}
        />
      </div>
      <div className={styles.thumbnails}>{thumbnails}</div>
    </div>
  );
};
export default MediaCarousel;
